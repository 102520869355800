interface ITitle {
    sectionTitle: string;
    pageTitle: string;
    params?: object;
}

export const getTitleByLocation = (pathname: string): ITitle => {
    if (pathname === '/dashboard') {
        return {sectionTitle: '', pageTitle: 'header.titles.dashboard.dashboard'};
    }

    if (pathname === '/dashboard/years') {
        return {sectionTitle: 'header.titles.dashboard.dashboard', pageTitle: 'Years Details'};
    }

    if (!!pathname.match(/^\/dashboard\/years\/[0-9]{4}$/)) {
        const year = pathname.replace(/^\/dashboard\/years\/([0-9]{4})$/, '$1');

        return {sectionTitle: 'header.titles.dashboard.dashboard', pageTitle: 'header.titles.dashboard.year_details', params: {year}};
    }

    if (pathname === '/dashboard/charts') {
        return {sectionTitle: 'header.titles.dashboard.dashboard', pageTitle: 'header.titles.dashboard.charts'};
    }

    if (pathname === '/dashboard/companies') {
        return {sectionTitle: 'header.titles.dashboard.dashboard', pageTitle: 'header.titles.dashboard.companies'};
    }

    if (pathname === '/transactions') {
        return {sectionTitle: '', pageTitle: 'header.titles.transactions.transactions'};
    }

    if (pathname === '/transactions/bulk') {
        return {sectionTitle: 'header.titles.transactions.transactions', pageTitle: 'header.titles.transactions.bulk'};
    }

    if (!!pathname.match(/^\/transactions\/[A-Za-z0-9-]+$/)) {
        return {sectionTitle: 'header.titles.transactions.transactions', pageTitle: 'header.titles.transactions.details'};
    }

    if (!!pathname.match(/^\/transactions\/[A-Za-z0-9-]+\/edit$/)) {
        return {sectionTitle: 'header.titles.transactions.transactions', pageTitle: 'header.titles.transactions.edit'};
    }

    if (pathname === '/extra-incomes') {
        return {sectionTitle: '', pageTitle: 'Extra Income'};
    }

    if (pathname === '/extra-incomes/new') {
        return {sectionTitle: 'Extra Income', pageTitle: 'New extra income'};
    }

    if (pathname === '/events') {
        return {sectionTitle: '', pageTitle: 'header.titles.events.events'};
    }

    if (!!pathname.match(/^\/events\/[0-9]+$/)) {
        return {sectionTitle: 'header.titles.events.events', pageTitle: 'header.titles.events.details'};
    }

    if (pathname === '/events/new') {
        return {sectionTitle: 'header.titles.events.events', pageTitle: 'header.titles.events.new'};
    }

    if (pathname === '/recurring-expenses') {
        return {sectionTitle: '', pageTitle: 'header.titles.recurring_expenses.recurring_expenses'};
    }

    if (pathname === '/recurring-expenses/new') {
        return {sectionTitle: 'header.titles.recurring_expenses.recurring_expenses', pageTitle: 'header.titles.recurring_expenses.new'};
    }

    if (!!pathname.match(/^\/recurring-expenses\/[0-9]+\/edit$/)) {
        return {sectionTitle: 'header.titles.recurring_expenses.recurring_expenses', pageTitle: 'header.titles.recurring_expenses.edit'};
    }

    if (pathname === '/settings' || pathname === '/settings/general') {
        return {sectionTitle: '', pageTitle: 'header.titles.settings.settings'};
    }

    if (pathname === '/settings/profile') {
        return {sectionTitle: 'header.titles.settings.settings', pageTitle: 'header.titles.settings.profile'};
    }

    if (pathname === '/settings/change_password') {
        return {sectionTitle: 'header.titles.settings.settings', pageTitle: 'header.titles.settings.change_password'};
    }

    if (pathname === '/settings/payment_methods') {
        return {sectionTitle: 'header.titles.settings.settings', pageTitle: 'header.titles.settings.payment_methods'};
    }

    if (pathname === '/settings/budgets') {
        return {sectionTitle: 'header.titles.settings.settings', pageTitle: 'header.titles.settings.budgets'};
    }

    if (!!pathname.match(/^\/settings\/budgets\/[0-9]+$/)) {
        return {sectionTitle: 'header.titles.settings.settings', pageTitle: 'header.titles.settings.budgets'};
    }

    if (pathname === '/settings/incomes') {
        return {sectionTitle: 'header.titles.settings.settings', pageTitle: 'header.titles.settings.incomes'};
    }

    if (pathname === '/settings/allowances') {
        return {sectionTitle: 'header.titles.settings.settings', pageTitle: 'header.titles.settings.allowances'};
    }

    if (pathname === '/settings/custom_categories') {
        return {sectionTitle: 'header.titles.settings.settings', pageTitle: 'header.titles.settings.custom_categories'};
    }

    return {sectionTitle: '', pageTitle: ''};
};
