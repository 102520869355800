import * as React from "react";
import {Currency} from "../../../../../config/currencies";
import {DashboardItem} from "../../../Components/DashboardItem";
import {Loader} from "../../../Components/Loader";
import Chart from "react-google-charts";
import {prefillMonthsOfTheYear} from "../../../../../helpers/dashboard_charts_helpers";
import {IMonthSnapshotItem} from "../../../../../config/snapshot";
import moment from "moment";

interface ICurrentYearMonthsItemProps {
    data: IMonthSnapshotItem[];
    currency: Currency;
}

const CHART_HEADER = [
    {type: 'date', label: 'Month'},
    {type: 'number', label: 'Amount'},
];

export const CurrentYearMonths = ({data, currency}: ICurrentYearMonthsItemProps) => {
    const buildData = () => {
        const chartData = prefillMonthsOfTheYear(data).map(item => {
            const month = moment(item.month);

            return [
                {
                    v: month.toDate(),
                    f: month.format('MMMM'),
                },
                {
                    v: item.total,
                    f: (item.total as any).toCurrency(currency),
                },
            ];
        }) as any;

        return [CHART_HEADER].concat(chartData);
    };

    return (
        <DashboardItem title="Current year overview" fullWidth styles={{
            root: {
                height: '100%',
            },
            paper: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            },
            main: {
                flexGrow: 1,
            },
        }}>
            <Chart
                height={"100%" as any}
                chartType="Bar"
                loader={<Loader />}
                data={buildData()}
                options={{
                    legend: {
                        position: 'none',
                    },
                }}
            />
        </DashboardItem>
    );
};
