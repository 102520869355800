import * as React from "react";
import {AppBarSpacer} from "../Components/AppBarSpacer";
import {observer} from "mobx-react";
import {Divider, Drawer, useMediaQuery, useTheme} from "@mui/material";
import MenuList from "./MenuList";
import {UserAvatar} from "./UserAvatar";
import {drawerWidth} from "../../../config/layout";
import {useGlobalStores} from "../../../hooks/use_global_stores";

interface ILeftSideBarProps {
    open: boolean;
    onClose(): void;
}

const LeftSideBarComponent = ({open, onClose}: ILeftSideBarProps) => {
    const { userStore } = useGlobalStores();
    const isLargeScreen = useMediaQuery(useTheme().breakpoints.up('lg'));

    const user = userStore.currentUser;

    return (
        <Drawer
            variant={!isLargeScreen ? "temporary" : "permanent"}
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiDrawer-paper': {
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    width: drawerWidth,
                    transition: (theme) => theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                },
            }}
        >
            {isLargeScreen && <AppBarSpacer />}

            <UserAvatar user={user ? user.email[0] : 'EM'} />

            <Divider />

            <MenuList onItemClick={onClose} />
        </Drawer>
    );
};

export const LeftSideBar = observer(LeftSideBarComponent);
