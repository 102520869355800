import * as React from "react";
import { Box, alpha, styled } from "@mui/material";
import { SectionTitle } from "./SectionTitle";
import { Item } from "./Item";

const Container = styled(Box)(({theme}) => ({
    border: 'solid 1px',
    borderColor: theme.palette.divider,
    borderRadius: 5,
    marginTop: theme.spacing(0.5),
    // backgroundColor: alpha(theme.palette.grey[100], 0.6),
    backgroundColor: '#fff',
}));

const ItemContainer = styled(Box)(({theme}) => ({
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.divider,
    padding: theme.spacing(1.5),

    '&:last-of-type': {
        borderBottom: 0,
    },
}));

interface ISection {
    title?: string;
    value: React.ReactElement | any;
    icon?: React.ReactElement;
    noIcon?: boolean;
    onClick?(): void;
}

interface ISectionProps {
    title: string;
    sections: ISection[];
}

export const Section = ({title, sections}: ISectionProps) => (
    <>
        <SectionTitle title={title} />
        <Container>
            {sections.map((section, index) => (
                <ItemContainer key={index} onClick={section.onClick}>
                    <Item clickable={!!section.onClick} title={section.title} value={section.value} icon={section.icon} noIcon={section.noIcon} />
                </ItemContainer>
            ))}
        </Container>
    </>
);
