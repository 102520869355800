import * as React from "react";
import {Box, Grid, List, ListItemButton} from "@mui/material";
import {ITransaction} from "../../../../../config/transactions";
import { getDisplayLabel } from "../../../../../helpers/transaction_helpers";
import Avatar from '@mui/material/Avatar';
import {styled} from "@mui/material";
import { AmountField } from "./AmountField";
import { Currency } from "../../../../../config/currencies";
import { Business } from "@mui/icons-material";
import { isIphone } from "../../../../../services/user_agent_helper";
import { Icon } from "../../../../Components/Icons/Icon";
import { Link } from "react-router-dom";

interface IStyledLinkProps {
    selectable: boolean;
}

const StyledList = styled(List, {
    shouldForwardProp: (prop) => prop !== 'selectable',
})<IStyledLinkProps>(({theme, selectable}) => ({
    display: 'flex',
    flexGrow: 1,

    padding: 0,
    marginRight: theme.spacing(-1),

    ...(selectable ? {
        marginLeft: theme.spacing(-1),
    } : {
        marginLeft: theme.spacing(-1),
    }),
}));

const StyledListItemButton = styled(ListItemButton, {
    shouldForwardProp: (prop) => prop !== 'selectable',
})<IStyledLinkProps>(({theme, selectable}) => ({
    display: 'flex',
    flexGrow: 1,
    color: theme.palette.text.primary,
    textDecoration: 'none',

    ...(selectable ? {
        paddingLeft: theme.spacing(0),
        marginLeft: theme.spacing(-1),
    } : {}),

    '&:hover': {
        color: theme.palette.text.primary,
    },

    '&:active': {
        color: theme.palette.text.primary,
    },
}));

const PrimaryBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const PrimaryText = styled('div')(({theme}) => ({
    display: 'inline-block',
    overflow: 'hidden',
    maxWidth: 210,
    marginRight: theme.spacing(1),
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontWeight: 600,
}));

const Date = styled('div')(() => ({
    display: 'inline-block',
    fontSize: 11,
    textAlign: 'right',
}));

const SecondaryBox = styled(Box)(({theme}) => ({
    color: theme.palette.grey[700],
}));

const LogoContainer = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    border: 'solid 1px',
    borderColor: theme.palette.divider,
    borderRadius: '100%',
    width: 40,
    height: 40,

    '& > img': {
        width: 26,
        height: 26,
    },
}));

interface IDescriptiveFieldProps {
    transaction: ITransaction;
    currency: Currency;
    selectable: boolean;
}

export const DescriptiveField = ({transaction, currency, selectable}: IDescriptiveFieldProps) => {
    if (isIphone) {
        return (
            <StyledList selectable={selectable}>
                <StyledListItemButton selectable={selectable} {...{component: Link, to: `/transactions/${transaction.transaction_id}`}}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            {!!transaction.location?.logo ?
                                (
                                    <LogoContainer>
                                        <Icon file={transaction.location?.logo} abosolutePath />
                                    </LogoContainer>
                                ) :
                                (
                                    <Avatar>
                                        <Business />
                                    </Avatar>
                                )
                            }
                        </Grid>
                        <Grid item sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                            <PrimaryBox>
                                <PrimaryText>{getDisplayLabel(transaction)}</PrimaryText>
                                <Date>{transaction.purchased_at.format('MMM. DD')}</Date>
                            </PrimaryBox>

                            <SecondaryBox>
                                <AmountField transaction={transaction} currency={currency} />
                            </SecondaryBox>
                        </Grid>
                    </Grid>
                </StyledListItemButton>
            </StyledList>
        )
    }

    return (
        <Box>{getDisplayLabel(transaction)}</Box>
    );
};
