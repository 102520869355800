import * as React from "react";
import {IStatsDay} from "../../../config/stats";
import {useState} from "react";
import {GoogleDaysChart} from "./DaysChart/GoogleDaysChart";
import moment from "moment";
import {DayDetailChart} from "./DaysChart/DayDetailChart";

interface IDaysChartProps {
    days: IStatsDay[];
    chartHeight: number;
}

export const DaysChart = ({days, chartHeight}: IDaysChartProps) => {
    const [selectedDay, setSelectedDay] = useState<moment.Moment>();

    const handleDaySelect = (day: any) => {
        setSelectedDay(moment(day[0].v));
    };

    return (
        <>
            <GoogleDaysChart days={days} chartHeight={chartHeight} onSelect={handleDaySelect} />
            {selectedDay && <DayDetailChart day={selectedDay} />}
        </>
    );
};
