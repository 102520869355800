import * as React from "react";
import Chart from "react-google-charts";
import {Loader} from "../Components/Loader";
import "../../../utils/array_extentions";
import {CurrencyLabel} from "../../../config/currencies";
import {IStatsCurrency} from "../../../config/stats";

interface ICurrenciesChartProps {
    currencies: IStatsCurrency[];
    chartHeight: number;
}

const CHART_HEADER = [
    {type: 'string', label: 'Currency'},
    {type: 'number', label: 'Amount'},
];

export const CurrenciesChart = ({currencies, chartHeight}: ICurrenciesChartProps) => {
    const buildData = () => {
        let data = currencies as any;

        data = data.removeNegativeValuesFromObject('total');
        data.sortArrayOfObjectsDesc('total');
        data = data.map((item: IStatsCurrency) => {
            return [
                CurrencyLabel[item.code],
                {
                    v: item.total,
                    f: (item.total as any).toCurrency(item.code),
                }
            ];
        }) as any;

        return [CHART_HEADER].concat(data);
    };

    return (
        <Chart
            height={chartHeight}
            chartType="PieChart"
            loader={<Loader/>}
            data={buildData()}
            options={{
                is3D: true,
                legend: {
                    position: 'bottom',
                },
                pieSliceText: 'label',
                chartArea: {
                    top: 0,
                    width: '100%',
                    height: '80%',
                },
            }}
        />
    );
};
