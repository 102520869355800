import * as React from "react";
import {Loader} from "../../../Components/Loader";
import Chart from "react-google-charts";
import {IMonthSnapshotItem} from "../../../../../config/snapshot";
import {buildCumulativeMonthsTotalSpentData} from "../../../../../helpers/dashboard_charts_helpers";

interface ICurrentYearTotalSpentProps {
    data: IMonthSnapshotItem[];
}

export const CurrentYearOverview = ({data}: ICurrentYearTotalSpentProps) => {
    const buildData = () => {
        return [
            ['Months', 'Total'],
            ['', 0],
        ].concat(buildCumulativeMonthsTotalSpentData(data));
    };

    return (
        <Chart
            height={150}
            chartType="LineChart"
            loader={<Loader />}
            data={buildData()}
            options={{
                series: {
                    0: { curveType: 'function' },
                },
                legend: {
                    position: 'none',
                },
                chartArea: {
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                },
                hAxis: {
                    gridlines: {
                        color: 'transparent'
                    },
                    baselineColor: 'transparent',
                },
                vAxis: {
                    gridlines: {
                        color: 'transparent'
                    },
                    baselineColor: 'transparent',
                },
            }}
        />
    );
};
