import * as React from "react";
import Chart from "react-google-charts";
import moment from "moment";
import {IStatsDay} from "../../../../config/stats";
import {prefillDays} from "../../../../helpers/dashboard_charts_helpers";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {Loader} from "../../Components/Loader";
import {memo, useEffect, useState} from "react";

interface IGoogleDaysChartProps {
    days: IStatsDay[];
    chartHeight: number;
    onSelect?(day: [
        {
            v: string;
            f: string;
        },
        {
            v: number;
            f: string;
        }
    ] | null): void;
}

export const GoogleDaysChart = memo(({days, chartHeight, onSelect}: IGoogleDaysChartProps) => {
    const { currencyStore } = useGlobalStores();

    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const CHART_HEADER = [
            {type: 'date', label: 'Day'},
            {type: 'number', label: 'Amount'},
        ];

        const chartData = prefillDays(days).map(item => {
            const day = moment(item.day);

            return [
                {
                    v: day.toDate(),
                    f: day.format('MMMM Do'),
                },
                {
                    v: item.total,
                    f: (item.total as any).toCurrency(currencyStore.currentCurrency),
                }
            ];
        }) as any;

        setData([CHART_HEADER].concat(chartData));
    }, []);

    const handleSelect = (chart: any) => (e: any) => {
        if (!onSelect) {
            return;
        }

        const selection = chart.getSelection();

        if (!selection.length) {
            onSelect(null);
            return;
        }

        const {row} = selection[0];
        onSelect(data[row + 1] as any);
    };

    return (
        <Chart
            height={chartHeight}
            chartType="Bar"
            loader={<Loader />}
            data={data}
            options={{
                legend: {
                    position: 'none',
                },
            }}
            chartEvents={[
                {
                    eventName: 'ready',
                    callback: ({ chartWrapper, google }) => {
                        const chart = chartWrapper.getChart();

                        google.visualization.events.addListener(chart as any, 'select' as any, handleSelect(chart));
                    },
                },
            ]}
        />
    );
}, (prevProps, nextProps) => {
    return true;
});
