import * as React from "react";
import Chart from "react-google-charts";
import {Loader} from "../Components/Loader";
import "../../../utils/array_extentions";
import {IStatsLocation} from "../../../config/stats";
import {getTopCompanies} from "../../../helpers/dashboard_charts_helpers";
import {useGlobalStores} from "../../../hooks/use_global_stores";

interface ICompaniesChartProps {
    locations: IStatsLocation[];
    chartHeight: number;
    showTopCompanies?: number;
}

const CHART_HEADER = [
    {type: 'string', label: 'Company'},
    {type: 'number', label: 'Amount'},
];

export const LocationsChart = ({locations, chartHeight, showTopCompanies}: ICompaniesChartProps) => {
    const { currencyStore } = useGlobalStores();

    const buildData = () => {
        let data = locations as any;

        data = data.removeNegativeValuesFromObject('total');
        data.sortArrayOfObjectsDesc('total');

        if (showTopCompanies) {
            data = getTopCompanies(data, showTopCompanies);
        }

        data = data.map((item: IStatsLocation) => {
            return [
                (item.name === '___NA___' ? 'Others...' : item.name),
                {
                    v: item.total,
                    f: (item.total as any).toCurrency(currencyStore.currentCurrency),
                }
            ];
        });

        return [CHART_HEADER].concat(data);
    };

    return (
        <Chart
            height={chartHeight}
            chartType="PieChart"
            loader={<Loader />}
            data={buildData()}
            options={{
                is3D: true,
                legend: {
                    position: 'bottom',
                },
                pieSliceText: 'label',
                chartArea: {
                    top: 0,
                    width: '100%',
                    height: '80%',
                },
            }}
        />
    );
};
