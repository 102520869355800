import * as React from "react";
import Chart from "react-google-charts";
import "../../../utils/array_extentions";
import {IStatsCategory} from "../../../config/stats";
import {getTopCategories} from "../../../helpers/dashboard_charts_helpers";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {Loader} from "../Components/Loader";

interface ICategoriesChartProps {
    categories: IStatsCategory[];
    chartHeight: number;
    showTopCategories?: number;
    showLegend?: boolean;
    callbacks?: {
        onMouseOver(category: IStatsCategory): void;
        onMouseOut(): void;
    };
}

const CHART_HEADER = [
    {type: 'string', label: 'Category'},
    {type: 'number', label: 'Amount'},
];

export const CategoriesChart = React.memo(({categories, chartHeight, showTopCategories, showLegend = true, callbacks}: ICategoriesChartProps) => {
    const { currencyStore } = useGlobalStores();

    let data = categories as any;

    data = data.removeNegativeValuesFromObject('total');
    data.sortArrayOfObjectsDesc('total');

    if (showTopCategories) {
        data = getTopCategories(data, showTopCategories);
    }

    const buildData = () => {
        return [CHART_HEADER].concat(data.map((item: IStatsCategory) => {
            return [
                (item.name !== '___NA___' ? item.name : null) || 'Others...',
                {
                    v: item.total,
                    f: (item.total as any).toCurrency(currencyStore.currentCurrency),
                }
            ];
        }) as any);
    };

    const handleMouseOver = (e: any) => {
        callbacks?.onMouseOver(data[e.row]);
    };
    const handleMouseOut = (e: any) => {
        callbacks?.onMouseOut();
    };

    return (
        <Chart
            height={chartHeight}
            chartType="PieChart"
            loader={<Loader />}
            data={buildData()}
            options={{
                is3D: true,
                legend: {
                    position: showLegend ? 'bottom' : 'none',
                },
                pieSliceText: 'label',
                chartArea: {
                    top: 0,
                    width: '100%',
                    height: showLegend ? '80%' : '100%',
                },
            }}
            chartEvents={[
                {
                    eventName: 'ready',
                    callback: ({ chartWrapper, google }) => {
                        if (!callbacks) {
                            return;
                        }

                        const chart = chartWrapper.getChart();

                        google.visualization.events.addListener(chart as any, 'onmouseover' as any, handleMouseOver);
                        google.visualization.events.addListener(chart as any, 'onmouseout' as any, handleMouseOut);
                    },
                },
            ]}
        />
    );
}, (prevProps, nextProps) => {
    return prevProps.categories === nextProps.categories;
});
