import * as React from "react";
import {fuelUnitLabel, IFuelDetails} from "../../../../../config/fuel";

interface IFuelAmountProps {
    value: IFuelDetails;
}

export const FuelDetails = ({value}: IFuelAmountProps) => (
    `${value.amount} ${fuelUnitLabel[value.unit]}`
);
