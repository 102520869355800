import React from 'react';
import {Suspense} from "react";
import {observer} from "mobx-react";
import {RootContainer} from "./components/Layout/RootContainer";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {createGlobalStores, GlobalStoresContext} from "./contexts/global_stores_context";
import {useGlobalStores} from "./hooks/use_global_stores";
import {Login} from "./components/Login/Login";
import {Dashboard} from "./components/Dashboard/Dashboard";
import {DashboardCharts} from "./components/Dashboard/DashboardCharts/DashboardCharts";
import {List as TransactionsList} from "./components/Transactions/List/List";
import {EditTransaction} from "./components/Transactions/Edit/EditTransaction";
import {ViewTransaction} from "./components/Transactions/View/ViewTransaction";
import {YearsList} from "./components/Dashboard/Years/List/YearsList";
import {YearView} from "./components/Dashboard/Years/View/YearView";
import {RecurringExpenses} from "./components/RecurringExpenses/RecurringExpenses";
import {NewRecurringExpense} from "./components/RecurringExpenses/New/NewRecurringExpense";
import {Settings} from "./components/Settings/Settings";
import {General} from "./components/Settings/General/General";
import {Profile} from "./components/Settings/Profile/Profile";
import {ChangePassword} from "./components/Settings/ChangePassword/ChangePassword";
import {UserPaymentMethods} from "./components/Settings/UserPaymentMethods/UserPaymentMethods";
import {PaymentMethodsList} from "./components/Settings/UserPaymentMethods/PaymentMethodsList";
import {Incomes} from "./components/Settings/Incomes/Incomes";
import {IncomesList} from "./components/Settings/Incomes/List/IncomesList";
import {RecurringExpensesList} from "./components/RecurringExpenses/List/RecurringExpensesList";
import {EditRecurringExpense} from "./components/RecurringExpenses/Edit/EditRecurringExpense";
import {Events} from "./components/Events/Events";
import {EventsList} from "./components/Events/List/EventsList";
import {EventView} from "./components/Events/View/EventView";
import {Bulk} from "./components/Transactions/Bulk/Import/Bulk";
import {Locations as DashboardLocations} from "./components/Dashboard/LocationsDetails/Locations";
import {Transactions} from "./components/Transactions/Transactions";
import {ExtraIncome} from "./components/ExtraIncome/ExtraIncome";
import {NewExtraIncome} from "./components/ExtraIncome/New/NewExtraIncome";
import {EditExtraIncome} from "./components/ExtraIncome/Edit/EditExtraIncome";
import {CustomCategories} from "./components/Settings/CustomCategories/CustomCategories";
import { Allowances } from './components/Settings/Allowances/Allowances';
import { AllowancesList } from './components/Settings/Allowances/List/AllowancesList';
import { Snapshot } from './components/Dashboard/Snapshot/Snapshot';
import { Budgets } from './components/Settings/Budgets/Budgets';
import { BudgetsList } from './components/Settings/Budgets/List/BudgetsList';
import { BudgetView } from './components/Settings/Budgets/View/BudgetView';
import { SnapshotV3 } from './components/Dashboard/SnapshotV3/SnapshotV3';
import { SnapshotVersion } from './config/snapshot';
import { FullPageLoader } from './components/Layout/Components/FullPageLoader';
import { Refresher } from './components/Refresher/Refresher';
import { ProPage } from './components/Pro/Components/ProPage';
import { Level } from './config/auth';
import { Feature } from './config/features';

const globalStore = createGlobalStores();
const customTheme = createTheme();

const App = observer(() => {
    const { authStore, generalSettings } = useGlobalStores();

    if (!authStore.isAccessTokenValid) {
        return (
            <Suspense fallback={<FullPageLoader />}>
                <Login />
            </Suspense>
        );
    }

    return (
        <GlobalStoresContext.Provider value={globalStore}>
            <ThemeProvider theme={customTheme}>
                <Suspense fallback={<FullPageLoader />}>
                    <BrowserRouter>
                        <Refresher>
                            <RootContainer>
                                <Routes>
                                    <Route>
                                        <Route index element={<Navigate replace to="/dashboard" />} />
                                        <Route path="dashboard" element={<Dashboard />}>
                                            <Route index element={
                                                generalSettings.settings.snapshotVersion === SnapshotVersion.V3 ?
                                                    <SnapshotV3 /> :
                                                    <Snapshot />
                                            } />
                                            <Route path="charts" element={<ProPage min={Level.PRO} feature={Feature.DASHBOARD_CHARTS}><DashboardCharts /></ProPage>} />
                                            <Route path="years" element={<ProPage min={Level.PRO} feature={Feature.DASHBOARD_YEARS_DETAILS}><YearsList /></ProPage>}>
                                                <Route path=":year" element={<YearView />} />
                                            </Route>
                                            <Route path="locations" element={<ProPage min={Level.PRO} feature={Feature.DASHBOARD_CHARTS}><DashboardLocations /></ProPage>} />
                                        </Route>
                                        <Route path="transactions" element={<Transactions />}>
                                            <Route index element={<TransactionsList />} />
                                            <Route path="bulk" element={<Bulk />} />
                                            <Route path=":transaction_id" element={<ViewTransaction />} />
                                            <Route path=":transaction_id/edit" element={<EditTransaction />} />
                                        </Route>
                                        <Route path="extra-incomes" element={<ProPage min={Level.PRO} feature={Feature.EXTRA_INCOMES}><ExtraIncome /></ProPage>}>
                                            <Route path="new" element={<NewExtraIncome />} />
                                            <Route path=":id/edit" element={<EditExtraIncome />} />
                                        </Route>
                                        <Route path="recurring-expenses" element={<ProPage min={Level.PRO} feature={Feature.RECURRING_EXPENSES}><RecurringExpenses /></ProPage>}>
                                            <Route index element={<RecurringExpensesList />} />
                                            <Route path="new" element={<NewRecurringExpense />} />
                                            <Route path=":id/edit" element={<EditRecurringExpense />} />
                                        </Route>
                                        <Route path="settings" element={<Settings />}>
                                            <Route index element={<Navigate replace to="/settings/general" />} />
                                            <Route path="general" element={<General />} />
                                            <Route path="profile" element={<Profile />} />
                                            <Route path="change_password" element={<ChangePassword />} />
                                            <Route path="payment_methods" element={<UserPaymentMethods />}>
                                                <Route index element={<PaymentMethodsList />} />
                                            </Route>
                                            <Route path="incomes" element={<Incomes />}>
                                                <Route index element={<IncomesList />} />
                                            </Route>
                                            <Route path="allowances" element={<ProPage min={Level.PRO} feature={Feature.ALLOWANCES}><Allowances /></ProPage>}>
                                                <Route index element={<AllowancesList />} />
                                            </Route>
                                            <Route path="budgets" element={<Budgets />}>
                                                <Route index element={<BudgetsList />} />
                                                <Route path=":id" element={<BudgetView />} />
                                            </Route>
                                            <Route path="custom_categories" element={<ProPage min={Level.PRO} feature={Feature.CUSTOM_CATEGORIES}><CustomCategories /></ProPage>} />
                                        </Route>
                                        <Route path="events" element={<ProPage min={Level.PRO} feature={Feature.EVENTS}><Events /></ProPage>}>
                                            <Route path="" element={<EventsList />}>
                                                <Route path=":id" element={<EventView />} />
                                            </Route>
                                        </Route>
                                    </Route>
                                </Routes>
                            </RootContainer>
                        </Refresher>
                    </BrowserRouter>
                </Suspense>
            </ThemeProvider>
        </GlobalStoresContext.Provider>
    );
});

export default App;
