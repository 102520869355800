import * as React from "react";
import Chart from "react-google-charts";
import {Loader} from "../Components/Loader";
import {IStatsDayOfTheWeek} from "../../../config/stats";
import {prefillDaysOfTheWeek} from "../../../helpers/dashboard_charts_helpers";
import "../../../utils/number_extensions";
import {useGlobalStores} from "../../../hooks/use_global_stores";

interface IDaysDistributionChartProps {
    days: IStatsDayOfTheWeek[];
    chartHeight: number;
}

const CHART_HEADER = [
    {type: 'string', label: 'Day'},
    {type: 'number', label: 'Amount'},
];

export const DaysDistributionChart = ({days, chartHeight}: IDaysDistributionChartProps) => {
    const { currencyStore } = useGlobalStores();

    const buildData = () => {
        const data = prefillDaysOfTheWeek(days).map((item) => {
            return [
                item.weekday_code,
                {
                    v: item.total,
                    f: (item.total as any).toCurrency(currencyStore.currentCurrency),
                },
            ];
        }) as any;

        return [CHART_HEADER].concat(data);
    };

    return (
        <Chart
            height={chartHeight}
            chartType="BarChart"
            loader={<Loader />}
            data={buildData()}
            options={{
                chartArea: {
                    top: 10,
                    left: 90,
                    width: '90%',
                    height: '80%',
                },
                legend: {
                    position: 'bottom',
                },
            }}
        />
    );
};
