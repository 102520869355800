import * as React from "react";
import {useEffect, useState} from "react";
import {Autocomplete, Box, debounce, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import {ILocation, ILocationSearchRequest, LOCATION_VENDOR, LocationVendor} from "../../../../config/location";
import {searchLocations} from "../../../../stores/locations";
import {GeoLocationAction, IPosition} from "./GeoLocationAction";
import {generateRandomId} from "../../../../utils/random";
import {EndAdornmentContainerHack} from "./EndAdornmentContainerHack";
import {IGoogleMapsLocation} from "../GoogleMapsLocationFinderField/MapView";
import {GoogleMapsLocationFinderField} from "../GoogleMapsLocationFinderField/GoogleMapsLocationFinderField";

export enum LocationFieldChangeScope {
    MANUAL_SELECTION,
    GOOGLE_MAPS_FINDER,
}

interface ILocationFieldAsyncProps {
    value?: ILocation | null;
    defaults?: ILocation[] | null;
    onChange(location: ILocation, scope: LocationFieldChangeScope): void;
}

export const LocationFieldAsync = ({value, onChange, defaults}: ILocationFieldAsyncProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<ILocation[]>(value ? [value] : []);
    const [sessionToken, setSessionToken] = useState<string>('');
    const [position, setPosition] = useState<IPosition>();
    const [location, setLocation] = useState(value);

    useEffect(() => {
        setSessionToken(generateRandomId(15));
    }, []);

    useEffect(() => {
        if (!!defaults?.length) {
            setOptions(defaults);
        }
    }, [defaults]);

    useEffect(() => {
        setLocation(value);
    }, [value]);

    const searchCompaniesDebounced = debounce((query: string) => {
        const request = {query} as ILocationSearchRequest;
        if (!!position) {
            request.session_token = sessionToken;
            request.lat = position.lat
            request.lon = position.lon;
        }

        searchLocations(request).then(locations => {
            setLoading(false);
            setOptions(locations || []);
        });
    }, 500);

    const handleInputChange = (event: object, value: string, reason: string) => {
        if (reason === 'reset') {
            return;
        }

        if (!loading) {
            setLoading(true);
        }

        if (value.trim() === '') {
            return;
        }

        searchCompaniesDebounced(value);
    };

    const handleGeoLocationChange = (active: boolean, position?: IPosition) => {
        setPosition(position);
    };

    const handleGoogleMapsChange = (value: IGoogleMapsLocation) => {
        const location = {
            name: value.name,
            vendor: LocationVendor.GOOGLE_MAPS,
            vendor_id: value.location_id,
            address: value.address,
            // default_category_id: '', @todo calculate it from value.categories
        };

        setOptions([location]);
        onChange(location, LocationFieldChangeScope.GOOGLE_MAPS_FINDER);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, location: ILocation) => {
        onChange(location, LocationFieldChangeScope.MANUAL_SELECTION);
    };

    return (
        <Box sx={{position: 'relative'}}>
            <Autocomplete
                options={options}
                // defaultValue={location || null}
                value={location || null}
                getOptionLabel={(option: ILocation) => option.name}
                groupBy={(option: ILocation) => LOCATION_VENDOR[option.vendor]}
                onChange={handleChange as any}
                multiple={false}
                onInputChange={handleInputChange}
                renderOption={(props, option: ILocation) => {
                    if (!option.address) {
                        return <li {...props}>{option.name}</li>;
                    }

                    return (
                        <li {...props}>
                            <div>
                                <Typography>
                                    {option.name}
                                </Typography>

                                <Typography variant="caption">
                                    {option.address}
                                </Typography>
                            </div>
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Location"
                        variant="outlined"
                    />
                )}
            />

            <EndAdornmentContainerHack sx={{display: 'flex', alignItems: 'center'}} hasValue={!!location}>
                {loading ? <CircularProgress color="primary" size={20}/> : null}
                {<GeoLocationAction onChange={handleGeoLocationChange} />}
                {<GoogleMapsLocationFinderField position={position} onChange={handleGoogleMapsChange} />}
            </EndAdornmentContainerHack>
        </Box>
    );
};
