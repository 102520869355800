import * as React from "react";
import {
    ITransactionDetailFormField, TransactionDetailFields
} from "../../../../../../../config/transaction_details";
import {Grid} from "@mui/material";
import {ITransactionDetails} from "../../../../../../../config/transactions";
import {TransactionValidationErrors} from "../../../../../../../validators/transactions";

interface IFormElementsListProps {
    value: ITransactionDetails;
    detailFields: ITransactionDetailFormField[];
    onDelete(field: ITransactionDetailFormField): void;
    onChange(details: ITransactionDetails): void;
    errors?: TransactionValidationErrors;
}

export const FormElementsList = ({value, onChange, onDelete, detailFields, errors}: IFormElementsListProps) => {
    const [values, setValues] = React.useState<ITransactionDetails>(value);

    React.useEffect(() => {
        setValues(value);
    }, [value]);

    React.useEffect(() => {
        onChange(values);
    }, [values]);

    const handleChange = (option: string) => (value: any) => {
        setValues({ ...values, [option]: value });
    };

    const handleDelete = (option: ITransactionDetailFormField) => (event: any) => {
        onDelete(option);

        setValues(prev => {
            const newData = Object.assign({}, prev);
            delete newData[option.code];

            return newData;
        });
    };

    return (
        <>
            {detailFields.map((item, index) => {
                const DetailElement = item.element as any;

                return (
                    <Grid item xs={12} key={index}>
                        <DetailElement
                            value={values[item.code] || '' as any}
                            onChange={handleChange(item.code)}
                            onDelete={handleDelete(item)}
                            errors={errors}
                        />
                    </Grid>
                )
            })}
        </>
    );
};
