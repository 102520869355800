import * as React from "react";
import { useGlobalStores } from "../../../hooks/use_global_stores";
import {Outlet} from "react-router-dom";
import { useModal } from "../../../hooks/use_modal";
import { FeaturesModal } from "./Components/FeaturesModal";
import { NoAccess } from "./Components/NoAccess";
import { Level } from "../../../config/auth";
import { Feature } from "../../../config/features";

interface IProPageProps {
    min: Level;
    feature?: Feature;
    children: any;
}

export const ProPage = ({min, feature, children}: IProPageProps) => {
    const { authStore } = useGlobalStores();
    const { open, handleModalOpen, handleModalClose } = useModal();

    if (!authStore.accessToken?.hasAccess(min)) {
        return (
            <>
                <NoAccess onClick={handleModalOpen} />
                <FeaturesModal open={open} onClose={handleModalClose} selectedFeature={feature} />
            </>
        );
    }

    return children;
};
