import * as React from "react";
import {CSSProperties} from "react";

// https://vectr.com/new
// https://svg2jsx.com/
// https://convertio.co/eps-svg/

export interface IConcreteIcon {
    style?: CSSProperties;
    className?: string;
}

export interface IGenericIcon extends IConcreteIcon {
    file: string;
    alt?: string;
    abosolutePath?: boolean;
}

export const Icon = ({file, style, abosolutePath = false, className = "", alt = ""}: IGenericIcon) => {
    if (!abosolutePath && file[0] !== '/') {
        file = '/' + file;
    }

    return (
        <img src={`${!abosolutePath ? process.env.REACT_APP_CDN_HOST : ''}${file}`} style={{display: 'block', ...style}} alt={alt} />
    );
};
