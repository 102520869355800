import * as React from "react";
import "../../../utils/number_extensions";
import {IStatsWeek} from "../../../config/stats";
import {Loader} from "../Components/Loader";
import Chart from "react-google-charts";
import {prefillWeeks} from "../../../helpers/dashboard_charts_helpers";
import "../../../utils/number_extensions";
import {useGlobalStores} from "../../../hooks/use_global_stores";

interface IWeeksChartProps {
    weeks: IStatsWeek[];
    chartHeight: number;
}

const CHART_HEADER = [
    {type: 'string', label: 'Week number'},
    {type: 'number', label: 'Weekdays'},
    {type: 'number', label: 'Weekend'},
];

export const WeeksChart = ({weeks, chartHeight}: IWeeksChartProps) => {
    const { currencyStore } = useGlobalStores();

    const buildData = () => {
        const data = prefillWeeks(weeks).map((item) => {
            return [
                `${(item.week as any).getNumberWithOrdinal()} week`,
                {
                    v: item.weekdays,
                    f: (item.weekdays as any).toCurrency(currencyStore.currentCurrency),
                },
                {
                    v: item.weekend,
                    f: (item.weekend as any).toCurrency(currencyStore.currentCurrency),
                }
            ];
        }) as any;

        return [CHART_HEADER].concat(data);
    };

    return (
        <Chart
            height={chartHeight}
            chartType="ColumnChart"
            loader={<Loader />}
            data={buildData()}
            options={{
                isStacked: true,
                chartArea: {
                    top: 10,
                    width: '90%',
                    height: '80%',
                },
                legend: {
                    position: 'bottom',
                },
            }}
        />
    );
};
