import * as React from "react";
import {CircularProgress, styled} from "@mui/material";

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
}));

export const Loader = () => (
    <Container>
        <CircularProgress />
    </Container>
);
