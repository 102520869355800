import * as React from "react";
import {IStatsCompany, IStatsLocation} from "../../../../config/stats";
import Paper from "@mui/material/Paper";
import {Logo} from "./Logo";
import {MissingLogo} from "./MissingLogo";
import {CompanyDetails} from "./CompanyDetails";
import {CompanyName} from "./CompanyName";
import {styled} from "@mui/material";

const Container = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
}));

const LogoContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

interface ICompanyCardProps {
    location: IStatsLocation;
}

export const CompanyCard = ({location}: ICompanyCardProps) => {
    return (
        <Container>
            <LogoContainer>
                {location.logo ? <Logo logo={location.logo} /> : <MissingLogo />}
            </LogoContainer>

            <CompanyName company={location} />
            <CompanyDetails company={location} />
        </Container>
    );
};
