import {AuthStore} from "../stores/auth";

const API_ENDPOINT_V1 = process.env.REACT_APP_API_HOST + '/api/v1';
const API_ENDPOINT_V2 = process.env.REACT_APP_API_HOST + '/api/v2';
const API_ENDPOINT_V3 = process.env.REACT_APP_API_HOST + '/api/v3';
// this has to be a method, because the token might change
const API_REQUEST_HEADERS = () => {
    return {
        Authorization: `Bearer ${AuthStore.getStoredAccessToken()}`,
    };
};

const fetchGetOpen = (url: string, query: any = {}, props: any = {}): Promise<any> => {
    return fetch(url + '?' + new URLSearchParams(query), {
        mode: 'cors',
        ...props
    });
};

const fetchGet = (url: string, query: any = {}, props: any = {}): Promise<any> => {
    return fetch(API_ENDPOINT_V1 + url + '?' + new URLSearchParams(query), {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
        },
        ...props
    });
};

const fetchGetV2 = (url: string, query: any = {}, props: any = {}): Promise<any> => {
    return fetch(API_ENDPOINT_V2 + url + '?' + new URLSearchParams(query), {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
        },
        ...props
    });
};

const fetchGetV3 = (url: string, query: any = {}, props: any = {}): Promise<any> => {
    return fetch(API_ENDPOINT_V3 + url + '?' + new URLSearchParams(query), {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
        },
        ...props
    });
};

const fetchPost = (url: string, data: any = {}): Promise<any> => {
    return fetch(API_ENDPOINT_V1 + url, {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
    });
};

const fetchPostV2 = (url: string, data: any = {}): Promise<any> => {
    return fetch(API_ENDPOINT_V2 + url, {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
    });
};


const fetchFormPost = (url: string, data: FormData): Promise<any> => {
    return fetch(API_ENDPOINT_V1 + url, {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
        },
        method: 'POST',
        body: data,
    });
};

const fetchDelete = (url: string, data: any = {}): Promise<any> => {
    return fetch(API_ENDPOINT_V1 + url, {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
            'Content-Type': 'application/json',
        },
        method: 'DELETE',
        body: JSON.stringify(data),
    });
};

const fetchDeleteV2 = (url: string, data: any = {}): Promise<any> => {
    return fetch(API_ENDPOINT_V2 + url, {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
            'Content-Type': 'application/json',
        },
        method: 'DELETE',
        body: JSON.stringify(data),
    });
};

const fetchPatch = (url: string, data: any = {}): Promise<any> => {
    return fetch(API_ENDPOINT_V1 + url, {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
            'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify(data),
    });
};

const fetchPut = (url: string, data: any = {}): Promise<any> => {
    return fetch(API_ENDPOINT_V1 + url, {
        mode: 'cors',
        headers: {
            ...API_REQUEST_HEADERS(),
            'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(data),
    });
};

export {fetchGetOpen, fetchGet, fetchGetV2, fetchGetV3, fetchPost, fetchPostV2, fetchFormPost, fetchPatch, fetchPut, fetchDelete, fetchDeleteV2}
