import "./number_extensions.d"
import {Currency} from "../config/currencies";

Number.prototype.getNumberWithOrdinal = function() {
    const _this = this as any;

    const s = ['th', 'st', 'nd', 'rd'];
    const v = _this as any % 100;

    return _this + (s[(v - 20) % 10] || s[v] || s[0]);
};

Number.prototype.percentageDifferenceAgainst = function(b: number): number {
    const _this = this as any;
    return - ((_this - b) / _this * 100);
};;

Number.prototype.toCurrency = function(currency: Currency, options: object = {}): string {
    const _this = this as any;
    return (new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, ...options }).format(_this));
};;
