import * as React from "react";
import Chart from "react-google-charts";
import {Loader} from "../Components/Loader";
import "../../../utils/array_extentions";
import {IStatsPaymentMethod} from "../../../config/stats";
import {useGlobalStores} from "../../../hooks/use_global_stores";

interface IPaymentMethodsChartProps {
    paymentMethods: IStatsPaymentMethod[];
    chartHeight: number;
}

const CHART_HEADER = [
    {type: 'string', label: 'Payment Method'},
    {type: 'number', label: 'Amount'},
];

export const PaymentMethodsChart = ({paymentMethods, chartHeight}: IPaymentMethodsChartProps) => {
    const { currencyStore } = useGlobalStores();

    const buildData = () => {
        let data = paymentMethods as any;

        data = data.removeNegativeValuesFromObject('total');
        data.sortArrayOfObjectsDesc('total');
        data = data.map((item: IStatsPaymentMethod) => {
            return [
                item.name || 'Others...',
                {
                    v: item.total,
                    f: (item.total as any).toCurrency(currencyStore.currentCurrency),
                }
            ];
        });

        return [CHART_HEADER].concat(data);
    };

    return (
        <Chart
            height={chartHeight}
            chartType="PieChart"
            loader={<Loader />}
            data={buildData()}
            options={{
                is3D: true,
                legend: {
                    position: 'bottom',
                },
                pieSliceText: 'label',
                chartArea: {
                    top: 0,
                    width: '100%',
                    height: '80%',
                },
            }}
        />
    );
};
