import {IStatsCategory, IStatsCompany, IStatsDay, IStatsDayOfTheWeek, IStatsWeek} from "../config/stats";
import moment from "moment";
import {Days} from "../config/days";
import {IMonthSnapshotItem} from "../config/snapshot";

export const prefillDays = (days: IStatsDay[]): IStatsDay[] => {
    if (days.length === 0) {
        return [];
    }

    const firstDayOfTheMonth = moment().startOf('month');
    const lastDayOfTheMonth = moment().endOf('month');

    const firstProvidedDay = moment(days[0].day);
    const lastProvidedDay = moment(days[days.length - 1].day);

    if (
        !firstProvidedDay.isSame(lastProvidedDay, 'month') ||
        !firstProvidedDay.isSame(firstDayOfTheMonth, 'month')
    ) {
        return days;
    }

    if (!firstProvidedDay.isSame(firstDayOfTheMonth)) {
        days.push({
            day: firstDayOfTheMonth.format('YYYY-MM-DD'),
            total: 0,
        });
    }

    if (!lastProvidedDay.isSame(lastDayOfTheMonth)) {
        days.push({
            day: lastDayOfTheMonth.format('YYYY-MM-DD'),
            total: 0,
        });
    }

    return days;
};

export const prefillDaysOfTheWeek = (days: IStatsDayOfTheWeek[]): IStatsDayOfTheWeek[] => {
    const daysLength = days.length;
    if (daysLength === 7) {
        return days;
    }

    const allDays = new Array(7).fill(null).map((v, i) => {
        return {weekday: i, weekday_code: Days[i], total: 0}
    });

    for (let i = 0; i < daysLength; ++i) {
        allDays[days[i].weekday] = days[i];
    }

    return allDays;
};

export const prefillWeeks = (weeks: IStatsWeek[]): IStatsWeek[] => {
    const monthWeeks = getMonthWeeks();
    const monthWeeksLength = monthWeeks.length;
    const weeksLength = weeks.length;

    if (weeksLength === monthWeeksLength) {
        return weeks;
    }

    const firstWeekOfTheMonth = parseInt(moment().startOf('month').format('W'));
    const lastWeekOfTheMonth = parseInt(moment().endOf('month').format('W'));

    if (!weeks.length || (weeks[0].week >= firstWeekOfTheMonth && weeks[weeks.length - 1].week <= lastWeekOfTheMonth)) {
        const allWeeks = {} as any;
        for (let i = 0; i < monthWeeksLength; ++i) {
            allWeeks[monthWeeks[i]] = {week: monthWeeks[i], weekdays: 0, weekend: 0};
        }

        for (let i = 0; i < weeksLength; ++i) {
            allWeeks[weeks[i].week] = weeks[i];
        }

        return (Object as any).values(allWeeks);
    }

    return weeks;
};

const getMonthWeeks = (): number[] => {
    const firstDayOfTheMonth = moment().startOf('month');
    const lastDayOfTheMonth = moment().endOf('month');

    let day = firstDayOfTheMonth;
    const weeks = [];
    do {
        weeks.push(day.isoWeek());

        day = day.add(7, 'days');
    } while(day <= lastDayOfTheMonth);

    return weeks;
};

const getTopItems = (data: any[], max: number) => {
    const length = data.length;

    if (length <= max) {
        return data;
    }

    const items = [];

    let oneItemSkipped = -1;
    for (let i = 0; i <= max - 1; ++i) {
        // if the item has no name, skip it
        if (data[i].name === '___NA___') {
            // save the index of the skipped item
            oneItemSkipped = i;
            continue;
        }
        items.push(data[i]);
    }

    let total = 0;
    let nextBeginningIndex = max;

    // if there is a skipped item add it to the "other items" section
    if (oneItemSkipped > -1) {
        items.push(data[max]);
        total += data[oneItemSkipped].total;
        nextBeginningIndex += 1;
    }

    // add all the remaning items to the "other items" section
    for (let i = nextBeginningIndex; i <= length - 1; ++i) {
        total += data[i].total;
    }
    items.push({name: "Others...", total: total});

    return items;
};

export const getTopCompanies = (data: IStatsCompany[], max: number) => {
    return getTopItems(data, max);
};

export const getTopCategories = (data: IStatsCategory[], max: number) => {
    return getTopItems(data, max);
};

export const prefillMonthsOfTheYear = (months: IMonthSnapshotItem[]): IMonthSnapshotItem[] => {
    if (months.length === 0) {
        return [];
    }

    if (months.length === 12) {
        return months;
    }

    const tmpData = {} as any;

    const currentYear = moment().year();
    for (let i = 1; i <= 12; ++i) {
        tmpData[moment(`${currentYear}-${i}`, 'YYYY-MM').format('YYYY-MM')] = 0;
    }

    months.forEach(month => tmpData[month.month] = month.total);

    const data = [] as IMonthSnapshotItem[];
    Object.keys(tmpData).forEach((key) => data.push({month: key, total: tmpData[key]}))

    return data;
}

export const buildCumulativeMonthsTotalSpentData = (months: IMonthSnapshotItem[]): [string, number][] => {
    let currentTotal = 0;

    return months.map(month => {
        currentTotal += month.total;

        return [moment(month.month).format('MMMM'), currentTotal];
    });
}
